import { playButtonSound, playCloseDialogSound, playOpenDialogSound } from '@wg/web2clientapi/sound';
import classNames from 'classnames';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';

import { ComponentProps } from '@/components/Component';
import { t } from '~/helpers/localization';

import styles from './Dialog.module.scss';

const DEFAULT_ROOT_ID = 'modal-root';
const dialogFadeLeaveTimeout = 300;
const overlayFadeEnterTimeout = 300;
const overlayFadeLeaveTimeout = 300;

const overlayKey = 'overlay-fade';
const overlayName = 'overlay-fade';
const dialogKey = 'dialog-fade';
const dialogName = 'dialog-fade';

export type DialogSize =
    | 'small'
    | 'large'
    | 'extra-large'
    | 'extra-height'
    | 'fullscreen'
    | 'overlay'
    | 'introduction'
    | 'info'
    | 'worship';

export interface IProps extends ComponentProps {
    content: React.ReactNode;
    size: DialogSize;
    isOverlay: boolean;
    isVisible: boolean;
    className?: string;
    rootId?: string;
    hideDialog: () => void;
    overlayBackground?: string;
}

class Dialog extends React.PureComponent<IProps> {
    private nodeOverlayRef: React.RefObject<HTMLDivElement>;
    private nodeDialogRef: React.RefObject<HTMLDivElement>;

    constructor(props: IProps) {
        super(props);

        this.nodeOverlayRef = React.createRef();
        this.nodeDialogRef = React.createRef();
    }

    public componentDidMount() {
        document.addEventListener('keydown', this.onEscape, true);
    }

    public UNSAFE_componentWillReceiveProps(nextProps: IProps) {
        if (nextProps.isVisible !== this.props.isVisible) {
            nextProps.isVisible ? playOpenDialogSound() : playCloseDialogSound();
        }
    }

    public componentWillUnmount() {
        document.removeEventListener('keydown', this.onEscape, true);
    }

    public onEscape = (e: KeyboardEvent) => {
        if (e.keyCode === 27 && this.props.isVisible) {
            e.preventDefault();
            if (this.props.size !== 'introduction') {
                this.props.hideDialog();
            }
        }
    };

    public render() {
        if (!window) return null;
        const id = this.props.rootId ? this.props.rootId : DEFAULT_ROOT_ID;
        const modalRoot = window.document.getElementById(id);

        let overlay = <></>;
        let dialog = <></>;
        if (this.props.isVisible) {
            const classNameDialog = classNames(styles.dialog, styles[`dialog__${this.props.size}`]);
            const dialogContent = this.props.content ? this.props.content : null;
            const showCloseButton = !this.props.isOverlay && this.props.size !== 'introduction';

            const close = showCloseButton ? (
                <div
                    key="close"
                    className={styles.close}
                    onClick={(e) => {
                        e.preventDefault();
                        playButtonSound();
                        this.props.hideDialog();
                    }}
                >
                    {t('Закрыть')}
                </div>
            ) : null;

            overlay = (
                <div
                    ref={this.nodeOverlayRef}
                    key="overlay"
                    className={styles.overlay}
                    style={{ background: this.props.overlayBackground || '' }}
                />
            );
            dialog = (
                <div ref={this.nodeDialogRef} key="dialog" className={styles.dialogWrapper}>
                    <div className={classNameDialog}>
                        {close}
                        {dialogContent}
                    </div>
                </div>
            );
        }

        if (modalRoot) {
            return ReactDOM.createPortal(
                <div className={this.props.className || ''}>
                    <CSSTransition
                        nodeRef={this.nodeOverlayRef}
                        in={this.props.isVisible}
                        key={overlayKey}
                        classNames={overlayName}
                        timeout={{ enter: overlayFadeEnterTimeout, exit: overlayFadeLeaveTimeout }}
                    >
                        {overlay}
                    </CSSTransition>
                    <CSSTransition
                        nodeRef={this.nodeDialogRef}
                        in={this.props.isVisible}
                        key={dialogKey}
                        classNames={dialogName}
                        enter={false}
                        timeout={{ enter: 0, exit: dialogFadeLeaveTimeout }}
                    >
                        {dialog}
                    </CSSTransition>
                </div>,
                modalRoot,
            ) as React.ReactNode;
        } else {
            return null;
        }
    }
}

export default Dialog;
