import { playButtonSound } from '@wg/web2clientapi/sound';
import classNames from 'classnames';
import * as React from 'react';

import { ComponentProps } from '@/components/Component';

import DivTooltip from '../DivTooltip/DivTooltip';
import Tooltip from '../Tooltip/Tooltip';
import TooltipBody from '../Tooltip/TooltipBody';
import styles from './Button.module.scss';

export type ButtonType = 'orange' | 'secondary';

export interface ICustomizeProps extends ComponentProps {
    isIcon?: boolean;
    isLarger?: boolean;
    isSmaller?: boolean;
    isJustified?: boolean;
    isEllipsis?: boolean;
    isDisabled?: boolean;
    isFlat?: boolean;
    isHighlighted?: boolean;
    type?: ButtonType;
}

export interface IProps extends ICustomizeProps {
    children: React.ReactNode;
    onClick?: (event: React.MouseEvent) => void;
    onMouseOver?: (event: React.MouseEvent) => void;
    onMouseOut?: (event: React.MouseEvent) => void;
    onMouseEnter?: (event: React.MouseEvent) => void;
    onMouseLeave?: (event: React.MouseEvent) => void;
    tooltipContent?: React.ReactNode;
}

interface IState {
    labelWidth: number;
    containerWidth: number;
}

const buttonTypes: Record<ButtonType, string> = {
    orange: styles.isOrange,
    secondary: styles.isSecondary,
};

class Button extends React.PureComponent<IProps> {
    public container: HTMLDivElement | null;

    public label: HTMLSpanElement | null;

    public state: IState;

    constructor(props: IProps) {
        super(props);

        this.state = {
            labelWidth: 0,
            containerWidth: 0,
        };

        this.onClick = this.onClick.bind(this);
    }

    public componentDidMount() {
        setTimeout(() => {
            const cWidth = this.container ? this.container.offsetWidth : 0;
            const lWidth = this.label ? this.label.offsetWidth : 0;
            this.setState({
                labelWidth: lWidth,
                containerWidth: cWidth,
            });
        }, 0);
    }

    public onClick(event: React.MouseEvent) {
        event.stopPropagation();
        if (this.props.isDisabled) {
            return;
        }

        playButtonSound();
        // @ts-ignore
        event.currentTarget.blur();

        if (this.props.onClick) {
            this.props.onClick(event);
        }
    }

    get useEllipsis() {
        const ellipsisWidth = 10;
        const labelPaddings = 36;

        return (
            this.props.isEllipsis && this.state.labelWidth > this.state.containerWidth + ellipsisWidth - labelPaddings
        );
    }

    public render() {
        const useEllipsis = this.useEllipsis;
        const classNameContainer = classNames(styles.container, {
            [styles.isJustified]: this.props.isJustified,
        });

        const classNameButton = classNames(styles.button, {
            [buttonTypes[this.props.type || 'secondary']]: !!this.props.type,
            [styles.isIcon]: !!this.props.isIcon,
            [styles.isLarger]: !!this.props.isLarger,
            [styles.isDisabled]: !!this.props.isDisabled,
            [styles.isHighlighted]: !!this.props.isHighlighted,
            [styles.isFlat]: !!this.props.isFlat,
            [styles.isSmaller]: !!this.props.isSmaller,
        });

        const classNameInner = useEllipsis
            ? classNames(styles.inner, {
                  [styles.isEllipsis]: this.props.isEllipsis,
              })
            : styles.inner;

        let tooltipContent = this.props.tooltipContent;

        if (!tooltipContent && useEllipsis) {
            tooltipContent = <TooltipBody>{this.props.children}</TooltipBody>;
        }

        const tooltip = tooltipContent ? <Tooltip>{tooltipContent}</Tooltip> : null;

        const btn = (
            <button
                className={classNameButton}
                onClick={this.onClick}
                onMouseOver={this.props?.onMouseOver}
                onMouseOut={this.props?.onMouseOut}
                onMouseEnter={this.props?.onMouseEnter}
                onMouseLeave={this.props?.onMouseLeave}
            >
                {/* <div className={styles.decor} /> */}
                <div className={classNameInner}>
                    <span
                        ref={(c) => {
                            this.label = c;
                        }}
                    >
                        {this.props.children}
                    </span>
                </div>
            </button>
        );

        if (tooltip) {
            return (
                <DivTooltip className={classNames(classNameContainer, this.props.className)} tooltipBody={tooltip}>
                    {btn}
                </DivTooltip>
            );
        } else {
            return (
                <div
                    className={classNames(classNameContainer, this.props.className)}
                    ref={(c) => {
                        this.container = c;
                    }}
                >
                    {btn}
                </div>
            );
        }
    }
}

export default Button;
