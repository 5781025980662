import classNames from 'classnames';
import * as React from 'react';
import { CSSTransition } from 'react-transition-group';

import { ComponentProps } from '@/components/Component';

import styles from './Processing.module.scss';

const overlayFadeEnterTimeout = 200;
const overlayFadeLeaveTimeout = 200;

export interface IProps extends ComponentProps {
    label?: string;
    isFetching?: boolean;
    isInside?: boolean;
    useSpinner?: boolean;
}

const Processing = (props: IProps) => {
    const { label, isFetching, isInside, useSpinner, className } = props;

    const [inProp, setInProp] = React.useState(isFetching);
    const nodeRef = React.useRef(null);

    React.useEffect(() => {
        setInProp(!!isFetching);
    }, [isFetching]);

    const render = () => {
        if (useSpinner) {
            return (
                <div key="loader" ref={nodeRef} className={classNames(styles.processingSpinner, className)}>
                    <div className={styles.processingSpinnerInner}>
                        <div className={styles.processingSpinnerIcon} />
                        <div className={styles.processingSpinnerText}>{label}</div>
                    </div>
                </div>
            );
        } else {
            return (
                <div key="spinner" ref={nodeRef} className={classNames(
                    {
                        [styles.processingInside]: !!isInside,
                        [styles.processing]: !isInside,
                    },
                    className
                )}>
                    <div className={styles.spinner} />
                    {!!label ? <div className={styles.label}>{label}</div> : null}
                </div>
            );
        }
    };

    return (
        <CSSTransition
            classNames="overlay-fade"
            nodeRef={nodeRef}
            in={inProp}
            timeout={{ enter: overlayFadeEnterTimeout, exit: overlayFadeLeaveTimeout }}
        >
            {isFetching ? render() : <></>}
        </CSSTransition>
    );
};

export default Processing;
