import React, { useMemo } from 'react';

import { ComponentProps } from '@/components/Component';


const INTERPOLATE_PREFIX = '%(';
const INTERPOLATE_SUFFIX = ')s';
const INTERPOLATE_REGEXP = /%\(\w+\)s/g;


export interface IProps extends ComponentProps {
  str: string;
  [key: string]: string | number | React.ReactNode;
}

const Interpolate: React.FC<IProps> = (props) => {
  const substrings = useMemo(() => props.str.split(INTERPOLATE_REGEXP), [props.str]);
  const components = useMemo(() => props.str.match(INTERPOLATE_REGEXP), [props.str]);

  return (
    <div className={props.className || ''}>
      {substrings.map((substring, i) => {
        const component = components?.[i]?.replace(INTERPOLATE_PREFIX, '').replace(INTERPOLATE_SUFFIX, '');

        return (
          <React.Fragment key={`substring_${i}_${component}`}>
            <span dangerouslySetInnerHTML={{ __html: substring }} />
            {component && props[component]
              ? <div style={{display: 'inline-block'}}>
                  {props[component]}
                </div>
              : null
            }
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default React.memo(Interpolate);
