// @ts-ignore
import { sendCommand } from '@wg/web2clientapi/core/command';
import React from 'react';
import { ToastContainer, toast } from 'react-toastify';

import { CurrencyType } from '../Currency/Currency';
import styles from './NotificationManager.module.scss';
import NotificationTemplate from './NotificationTemplate';

const MESSAGE_TIMEOUT = 1000 * 4;

export interface NotificationData {
    hasHeader?: boolean;
    header?: string;
    message: string;
    cost?: number;
    currency?: CurrencyType;
    isError?: boolean;
}

class NotificationManager extends React.PureComponent {
    styles = styles;

    static sendWebNotification = (params: NotificationData) => {
        const renderedContents = <NotificationTemplate customFields={params} classNames={''} />;
        toast(renderedContents, {
            position: toast.POSITION.BOTTOM_RIGHT,
        })
    };

    static sendNotification = (params: NotificationData) => {
        const messageParams: NotificationData = {
            ...params,
        };

        if (params.cost) {
            messageParams.currency = messageParams.currency || 'gold';
        }

        if (window?.jsHostQuery && typeof window.jsHostQuery === 'function') {
            sendCommand({
                command: 'notification',
                params: params,
            });
        } else {
            NotificationManager.sendWebNotification(params);
        }
    };

    render() {
        return (
            <>
                <ToastContainer 
                    key={'AlertProvider'}
                    autoClose={MESSAGE_TIMEOUT}
                    limit={5}
                    newestOnTop={true}
                    rtl={false}
                    hideProgressBar={true}
                    pauseOnFocusLoss={false}
                    pauseOnHover={false}
                    draggable={false}
                    closeOnClick
                />
            </>
        );
    }
}

export default NotificationManager;
