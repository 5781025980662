import * as React from 'react';
import { CSSTransition } from 'react-transition-group';

import { ComponentProps } from '@/components/Component';

export interface StepCSSTransitionGroupProps extends ComponentProps {
    level: number;
    children: React.ReactNode;
    id?: string;
}

const transitionAppearTimeout = 0;
const transitionEnter = false;
const transitionLeave = false;

class StepCSSTransitionGroup extends React.PureComponent<StepCSSTransitionGroupProps, any> {
    private nodeRef: React.RefObject<HTMLDivElement>;

    constructor(props: StepCSSTransitionGroupProps) {
        super(props);

        this.nodeRef = React.createRef();
    }

    public render() {
        let content = null;
        if (this.props.children !== undefined && this.props.children !== null) {
            content = (
                <CSSTransition
                    id={this.props.id}
                    in={true}
                    nodeRef={this.nodeRef}
                    className={this.props.className}
                    classNames={{
                        appear: 'step',
                        appearActive: `step-active-${this.props.level}`,
                       }}
                    appear={true}
                    enter={transitionEnter}
                    exit={transitionLeave}
                    timeout={{ appear: transitionAppearTimeout }}
                >
                    <div ref={this.nodeRef}>
                        {this.props.children}
                    </div>
                </CSSTransition>
            );
        }

        return content;
    }
}

export default StepCSSTransitionGroup;
