import classnames from 'classnames';
import React from 'react';
import isEqual from 'react-fast-compare';

import { ComponentProps } from '@/components/Component';

import { NotificationData } from './NotificationManager';
import styles from './NotificationTemplate.module.scss';

export interface NotificationTemplateProps extends ComponentProps {
    customFields: NotificationData;
    classNames?: string;
    styles?: any;
}

const NotificationTemplate = React.memo((props: NotificationTemplateProps) => {
    const isError = !!props.customFields.isError;

    const wrapper = classnames(styles.wrapper, props.className, !!props.classNames ? {
        [props.classNames]: !!props.classNames,
    } : '');

    const bodyClassname = classnames(styles.body, {
        [styles.errorBody]: isError,
        [styles.taggedBody]: !props.customFields.header && !isError,
    });

    return (
        <div className={wrapper} style={props.styles}>
            {props.customFields.header && (
                <div className={styles.header} dangerouslySetInnerHTML={{ __html: props.customFields.header }} />
            )}
            <div className={bodyClassname}>
                <div className={styles.bodyContent} dangerouslySetInnerHTML={{ __html: props.customFields.message }} />
            </div>
        </div>
    );
}, isEqual);

export default NotificationTemplate;
