import classNames from 'classnames';

import { ComponentProps } from '@/components/Component';

import styles from './Spinner.module.scss';

export interface IProps extends ComponentProps {
    label?: string;
}

const Spinner = (props: IProps) => (
    <div className={classNames(styles.container, props.className)}>
        <div className={styles.spinner} />
        {props.label && <div className={styles.label}>{props.label}</div>}
    </div>
);

export default Spinner;
