import { playCheckboxSound } from '@wg/web2clientapi/sound';
import classNames from 'classnames';
import * as React from 'react';

import { ComponentProps } from '@/components/Component';

import Checkbox from '../Checkbox/Checkbox';
import styles from './CheckboxWithLabel.module.scss';

export type OnChangeEvent = React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLDivElement>;

export interface IProps extends ComponentProps {
    labelText: React.ReactChild;
    isChecked?: boolean;
    isDisabled?: boolean;
    onChange: (value: boolean) => void;
}

export interface IState {
    isChecked?: boolean;
}

class CheckboxWithLabel extends React.PureComponent<IProps, IState> {
    public state = {
        isChecked: this.props.isChecked,
    };

    public onClick = (_: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        this.onChange(!this.props.isChecked);
    };

    public onChange = (value: boolean) => {
        if (this.props.isDisabled) {
            return;
        }

        playCheckboxSound();

        this.setState({
            isChecked: !(this.props.isChecked || this.state.isChecked),
        });

        if (this.props.onChange) {
            this.props.onChange(value);
        }
    };

    public render() {
        const isChecked =
            this.props.isChecked !== undefined && this.props.isChecked !== null
                ? this.props.isChecked
                : this.state.isChecked;
        const isDisabled = this.props.isDisabled;

        return (
            <div
                className={classNames(styles.container, {
                    [styles.isDisabled]: isDisabled,
                })}
                onClick={this.onClick}
            >
                <Checkbox onChange={this.onChange} isChecked={isChecked} isDisabled={isDisabled} />
                <span
                    className={classNames(
                        styles.checkboxLabel,
                        {
                            [styles.checked]: isChecked && !isDisabled,
                        },
                        this.props.className,
                    )} // TODO: Move className to parent Node
                >
                    {this.props.labelText}
                </span>
            </div>
        );
    }
}

export default CheckboxWithLabel;
